<!-- 模式布局 -->
<template>
  <div class="second" :style="bg">
    <div class="container-fluid sss">
      <div class="row secondBody">
        <div class="col-md-5 secondLeft">
          <div>
            <div class="left_1">
              <img src="2/英文字_06.png" alt="" />
            </div>
            <div class="left_2">公司简介</div>
            <div>
              <span class="left_3">湖南携梦科技有限公司</span
              ><span class="left_4"
                >详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细介绍文字详细文字详细介绍文字详细介绍文字详细介绍文字详细文字详细介绍文字详细介绍文字详细介绍文字详细文字详细介绍文字详细介绍文字详细介绍文字详细</span
              >
            </div>
          </div>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-6 imgBox">
          <img class="aboutUsImg" src="2/公司照片_03.png" />
        </div>
      </div>
    </div>

    <!-- 底部条 -->
    <div class="bottom sss">
      <div class="container-fluid">
        <div class="row bottomRow">
          <div
            class="col-12 col-sm-6 col-md-6 col-lg-3 bottomCard"
            v-for="(entity, index) in list"
            :key="index"
          >
            <div class="container-fruid">
              <div class="row bottomItem">
                <div class="col-3 col-sm-3 col-md-5 smallImgBox">
                  <img :src="entity.img" alt="" />
                </div>
                <div class="col-9 col-sm-9 col-md-7 txtBox">
                  <div class="bottomCard_1">{{ entity.name }}</div>
                  <div class="bottomCard_2">{{ entity.info }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- 脚本 -->
<script>
export default {
  data() {
    return {
      bg: {
        // 背景图片地址
        backgroundImage:
          "url(" + require("../../assets/images/2背景底图_02.png") + ")",
        // 背景图片是否重复
        backgroundRepeat: "no-repeat",
        // 背景图片大小
        backgroundSize: "cover",
        // 背景图片位置
        backgroundPosition: "center center",
      },
      list: [
        {
          img: "2/企业文化_111.png",
          name: "企业文化",
          info: "详细文字详细文字详细文字详细文字详细文字详细文字",
        },
        {
          img: "2/企业价值观_222.png",
          name: "企业价值观",
          info: "详细文字详细文字详细文字详细文字详细文字详细文字",
        },
        {
          img: "2/企业宗旨_333.png",
          name: "企业宗旨",
          info: "详细文字详细文字详细文字详细文字详细文字详细文字",
        },
        {
          img: "2/企业愿景_444.png",
          name: "企业愿景",
          info: "详细文字详细文字详细文字详细文字详细文字详细文字",
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {},
  },
};
</script>

<!-- 样式 -->
<style scoped>
.second {
  position: relative;
  /* margin-top: 100px; */
  width: 100%;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: space-around;

  padding-bottom: 100px;


}

.secondBody {
  display: flex;
  justify-content: center;
  align-items: center;
}

.secondLeft {
}

.secondLeft .left_1 {
  height: 100%;
  /* height: 120px; */
  /* margin-top: 91px; */
}

/* about us图片 */
.secondLeft .left_1 img {
  width: 263px;
  height: 51px;
}

/* 公司 */
.secondLeft .left_2 {
  margin-top: 26px;
  margin-bottom: 20px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
}

/* 简介 */
.secondLeft .left_3 {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
}

/* 简介 */
.secondLeft .left_4 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  color: #999999;
  line-height: 28px;
}

/* 图片盒子 */
.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 图片 */
.imgBox img {
  width: 100%;
  max-height: 40vh;
  /* height: calc(40vh); */
}

/* aboutus */
.aboutUsImg {
  width: 100%;
  height: auto;
}

/* 底部 */
.second .bottom {
  width: 100vw;
  background-color: #1977f3;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 30px 0;

  /* margin-top: 5%; */
}

.bottomRow {
  /* overflow: hidden; */
}

.second .bottom .container {
  margin: auto;
}

.bottomItem {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.bottomCard {
  height: 100px;
}

.second .bottom .bottomCard .bottomCard_1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.second .bottom .bottomCard .bottomCard_2 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;

  margin-top: 19px;
}

.smallImgBox {
  width: 100px;
  height: 100px;
  background-color: white;

  border-radius: 50px;
  overflow: hidden;
  position: relative;

  margin-right: 10px;
}

.txtBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.smallImgBox img {
  /* width: 25px; */
  /* height: 25px; */
  width: 30px;
  height: 30px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 767px) {
  .second .bottom {
    margin-top: 40px;
    padding: 20px 0;
    width: 100vw;
    background-color: #1977f3;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .secondLeft .left_1 {
    margin-bottom: 10px;
    height: 100%;
    margin-top: 20px;
  }

  .second {
  position: relative;
  /* margin-top: 100px; */
  width: 100%;

  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: space-around;

  padding-bottom: 20px;


}
}

@media screen and (max-width: 991px) {
  .smallImgBox {
    width: 50px;
    height: 50px;
    background-color: white;

    border-radius: 50px;
    /* overflow: hidden; */
    position: relative;

    margin-right: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .smallImgBox {
    width: 60px;
    height: 60px;
    background-color: white;

    border-radius: 50px;
    /* overflow: hidden; */
    position: relative;

    margin-right: 10px;
  }
}
</style>