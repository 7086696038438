<!-- 模式布局 -->
<template>
  <div class="four" :style="bg">
    <div class="emptyBox">
    </div>
    <div class="container-fruid fourBody">
      <div class="container">
        <!-- 地图行 -->
        <div class="row">
          <div class="col-md-12 mapbox">
            <!-- 联系我们 -->
            <div class="contactUs sss d-none d-sm-block" @click="reloadMap">
              <img src="4/英文标题_03.png" alt="" />
              <div class="contactUsTxt">联系我们</div>
            </div>

            <my-map></my-map>
          </div>
        </div>
        <!-- 线 -->
        <div class="row">
          <div class="line"></div>
        </div>
        <!-- 地址行 -->
        <div class="row addressRow">
          <div class="addressRow1">地址</div>
          <span class="addressRow2">
            湖南省长沙市岳麓区银盆南路奥克斯环球中心A座
          </span>
          <div class="d-none d-sm-block">
            <img src="4/英文标题_07.png" alt="" />
          </div>
        </div>
      </div>

      <div class="container">
        <div class="row road">
          <div
            class="col-12 col-sm-6 col-md-6 col-lg-3 tip"
            v-for="entity in list"
            :key="entity.id"
          >
            <div class="firstRow tip">{{ entity.type }}</div>
            <div v-if="entity.boss" class="boss">BOSS直聘 ></div>
            <div class="bottomRow tip">{{ entity.info }}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="emptyBox">
      
      <div class="beiAnHaoBox">
        <div v-on:click="myClick">湘ICP备2021003697号-1</div>&ensp;| Copyright © 2022-2025 changshaxiemeng.com All Rights Reserved.
      </div>
    </div>

    
  </div>
</template>

<!-- 脚本 -->
<script>
import MyMap from "../MyMap.vue";
export default {
  components: { MyMap },
  data() {
    return {

      list: [
        { id: 0, type: "商务合作：", info: "chagnshaxiemeng@163.com", boss: false },
        { id: 1, type: "推广合作：", info: "changshaxiemengtg@163.com", boss: false },
        {
          id: 2,
          type: "产品反馈：",
          info: "changshaxiemengfk@163.com",
          boss: false
        },
        { id: 3, type: "人事招聘：", info: "hr@xiemeng.com", boss: true },
      ],
      showmap: true,
      bg: {
        // 背景图片地址
        backgroundImage:
          "url(" + require("../../assets/images/4底图背景.png") + ")",
        // 背景图片是否重复
        backgroundRepeat: "no-repeat",
        // 背景图片大小
        backgroundSize: "cover",
        // 背景图片位置
        backgroundPosition: "center center",
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {},
    reloadMap() {},
    myClick(){
      window.open('https://beian.miit.gov.cn/#/Integrated/index', '_blank');
    },
  },
};
</script>

<!-- 样式 -->
<style scoped>

.four{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 60px;
}

/* 地图行 */
.mapbox {
  width: 100%;
  height: 38vh;
  /* background-color: gray; */

  position: relative;
}

.contactUs {
  z-index: 9;
  width: 310px;
  height: 160px;
  background-color: #1977f3;

  position: absolute;
  right: 0;
  top: -35px;

  padding-top: 30px;
  padding-left: 24px;
}

.contactUs img {
  height: 43px;
  width: 255px;
}

.contactUs .contactUsTxt {
  margin-top: 10px;

  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

/* 线 */
.line {
  margin-top: 30px;
  height: 1px;
  background-color: #111111;
}

/* 地址行 */
.addressRow {
  margin-top: 20px;

  position: relative;
}

.addressRow .addressRow1 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.addressRow .addressRow2 {
  margin-top: 10px;
  margin-bottom: 10px;

  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
}

.addressRow img {
  width: 20px;
  height: 24px;

  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.road {
  margin-top: 10px;
  padding-top: 10px;
  /* padding-top: 40px; */


  border-top-width: 1px;
  border-top-color: rgba(0, 0, 0, 0.05);
  border-top-style: solid;
}

/* 商务合作 */
.road .tip {
  /* border-width: 1px;
  border-color: red;
  border-style: solid; */

  margin-bottom: 0px;
}

.road .firstRow {
  float: left;

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.road .boss {
  /* width: 37px; */
  background-color: #5dd5c8;
  float: left;

  border-radius: 4px;
  margin-left: 6px;
  padding: 0 3px;

  height: 26px;
  width: 74px;

  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 26px;
}

.road .bottomRow {
  padding-top: 22px;
  padding-bottom: 10px;
  clear: both;

  font-size: 24px;
  font-family: DIN Alternate;
  font-weight: bold;
  color: #333333;
  line-height: 26px;
  
}

.emptyBox{
  display: flex;
  flex-direction: column;
  flex: 1;

  justify-content: flex-end;
  margin-bottom: 40px;
}

.beiAnHaoBox{
  display: flex; 
  justify-content: center;
  color: #999999;
  font-size: 12px;  
  margin-bottom: 10px;
}


@media screen and (max-width: 767px) {
  /* .mapbox {
    margin-top: 60px;
  } */

  .road .bottomRow {
    padding-top: 5px;
    clear: both;

    font-size: 28px;
    font-family: DIN Alternate;
    font-weight: bold;
    color: #333333;
    line-height: 26px;
  }
}

/* 邮箱字符变化 */
@media screen and (max-width: 991px) {
  .mapbox {
    width: 100%;
    height: 28vh;
  }

  .road .bottomRow {
    font-size: 18px;
  }
}

@media screen and (max-width: 1439px) {
  .road .bottomRow {
    font-size: 20px;
  }
}

@media screen and (max-width: 1199px) {
  .road .bottomRow {
    font-size: 18px;
  }
}

</style>