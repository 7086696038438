<template>

  <div id="app">
    <!-- 用路由，才能push页面 -->
    <router-view></router-view>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
export default {
  name: "App",
};
</script>

<style>

/* 去掉所有边距 */
* {
  margin: 0;
  padding: 0;
}
</style>
