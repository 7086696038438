<!-- 模式布局 -->
<template>
  <div>
    <div>内容</div>
  </div>
</template>

<!-- 脚本 -->
<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {},
  },
};
</script>

<!-- 样式 -->
<style scoped>
div {
  color: orange;
}
</style>