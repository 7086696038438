import Vue from 'vue/dist/vue.js'
//Vuex配置
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state:{ //全局的state
        count:0, //自定义的变量
        ChangeShowCom:true,
    },

    getters:{ //监听state值的变化
        getCount(state){
            return state.count
        },

        isShow(state){
            return state.ChangeShowCom
        },
    },

    //改变state的值
    //外面触发用这个：this.$store.commit("addCount", 1);  //addCount方法，后面是参数 
    mutations:{
        addCount(state,num){
            state.count = state.count+num
        },
        subCount(state,num){
            state.count = state.count-num
        },
        show(state){
            state.ChangeShowCom=true
        },
        hide(state){
            state.ChangeShowCom=false
        },
        
    },

    // Action 提交的是 mutation，而不是直接变更状态。
    // Action 可以包含任意异步操作
    //外面触发：this.$store.dispatch('getAddCount',1)
    actions:{
        getAddCount(context,num){
            context.commit('addCount',num) //这儿去触发mutations对应的方法
        },  
        getDelCount(context,num){
            context.commit('subCount',num)
        },
        showCom(context){
            context.commit('show')
        },
        hideCom(context){
            context.commit('hide')
        },
        
    },
})