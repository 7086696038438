<!-- 模式布局 -->
<template>
  <div class="top">
    <div class="container-fluid head">
      <!-- logo -->
      <div class="logo">
        <img src="1/第一屏_03.png" />
      </div>
      <!-- 右侧 -->
      <div
        class="norTip d-none d-sm-block d-sm-none d-md-block"
        v-for="(entity, index) in list"
        :key="index"
        @mouseenter="selectIndex(index)"
      >
        <div
          :class="{
            rightTxtDiv: selectPage != 3 - index,
            rightTxtDivSelect: selectPage == 3 - index,
          }"
        >
          {{ entity }}
        </div>
      </div>
    </div>
  </div>
</template>

<!-- 脚本 -->
<script>
export default {
  props: { selectPage: { type: Number, requires: true } ,selectBlock:Function},
  data() {
    return {
      list: ["联系我们", "团队介绍", "公司简介", "首页"],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {},

    selectIndex(index){
      // alert(index)
      this.selectBlock(3-index)
    }
  },
};
</script>

<!-- 样式 -->
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
}

.top {
  height: 100px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.head {
  height: 100%;

  padding: 0 14px;
}

.logo {
  display: inline-block;
  height: 100%;
  position: relative;
}

.logo img {
  height: 51px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -50%);
}

/* tip公用状态 */
.top .norTip {
  float: right;
  margin-left: 115px;
  height: 100px;

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  padding-top: 25px;
}


/* 未选中 */
.rightTxtDiv {
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  /* background-color: aqua; */

  height: 40px;
  display: flex;
  align-items: center;

  border-top-width: 0px;
  border-top-color: rgba(25, 119, 243, 1);
  border-top-style: solid;
  /* margin-top: 4px; */
}

/* 选中 */
.rightTxtDivSelect {
  font-size: 19px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(25, 119, 243, 1);
  /* background-color: aqua; */

  height: 40px;
  display: flex;
  align-items: center;

  border-top-width: 2px;
  border-top-color: rgba(25, 119, 243, 1);
  border-top-style: solid;
  /* margin-top: 3px; */
}

@media screen and (max-width: 767px) {
  .logo {
    display: block;
  }

  .logo img {
    height: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .top {
  height: 60px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
}

/* 最大900px 右侧菜单隐藏 */
@media screen and (max-width: 825px) {
  .top .norTip {
    float: right;
    margin-left: 40px;
    height: 100px;

    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding-top: 25px;
  }
}

/* 最小1280px */
/* @media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
    margin: 0 auto;
  }
} */
</style>