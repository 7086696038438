<!-- 模式布局 -->
<template>
  <div :style="bg" class="third">
    <div class="container-fluid backimg sss">
      <div class="container">
        <div class="row">
          <div class="col-md-12 logo">
            <img src="3/英文标题_03.png" alt="" />
          </div>
          <div class="col-md-12 logoTxt">团队介绍</div>
        </div>
      </div>

      <!-- 中间滚动层 -->
      <swiper class="myswiperContainer" ref="mySwiper" :options="swiperOptions">
        <swiper-slide
          class="scrollSide"
          v-for="(entity, index) in 1"
          :key="index"
        >
          <div class="container-fluid">
            <div class="row avatorRow">
              <div
                class="card col-lg-3 col-md-3 col-sm-6 col-6 sss"
                v-for="(entity, index) in list"
                :key="index"
              >
                <div>
                  <img :src="entity.img" alt="" />
                </div>

                <div class="namebox">
                  <div class="name">{{ entity.name }}</div>
                  <div class="word">{{ entity.info }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper-slide>
      </swiper>

      <!-- 自定义分页器 -->
      <!-- <div class="bottomPage">
      <div class="bottomSwith">
        <div
          v-if="selectIndex == 0"
          class="sideBtn preBtn"
          @click="leftPage(false)"
        >
          <img src="4/TeamIntroduc_ico01.png" alt="" />
        </div>
        <div
          v-if="selectIndex != 0"
          class="sideHeightBtn preBtn"
          @click="leftPage(false)"
        >
          <img src="4/TeamIntroduc_ico04.png" alt="" />
        </div>

        <div
          @click="gotoSwiperChange(index)"
          :class="{
            switch: true,
          }"
          v-for="(entity, index) in 4"
          :key="index"
        >
          <div
            :class="{
              'my-bullet': selectIndex != index,
              'my-bullet-active': selectIndex == index,
            }"
          ></div>
        </div>

        <div
          v-if="selectIndex == 3"
          class="sideBtn nextBtn"
          @click="leftPage(true)"
        >
          <img src="4/TeamIntroduc_ico02.png" alt="" />
        </div>
        <div
          v-if="selectIndex != 3"
          class="sideHeightBtn nextBtn"
          @click="leftPage(true)"
        >
          <img src="4/TeamIntroduc_ico05.png" alt="" />
        </div>
      </div>
    </div> -->
    </div>
  </div>
</template>




<script>
export default {
  name: "carrousel",
  data() {
    return {
      bg: {
        // 背景图片地址
        backgroundImage:
          "url(" + require("../../assets/images/3底图背景_02.png") + ")",
        // 背景图片是否重复
        backgroundRepeat: "no-repeat",
        // 背景图片大小
        backgroundSize: "cover",
        // 背景图片位置
        backgroundPosition: "center center",
      },
      selectIndex: 0,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
        on: {
          slideChangeTransitionStart: this.onSlideChange,
        },
      },
      list: [
        { img: "3/团队图片_01.png", name: "李晓明", info: "前端工程师" },
        { img: "3/团队图片_02.png", name: "张胜男", info: "UI设计师" },
        { img: "3/团队图片_03.png", name: "李小明", info: "程序员" },
        { img: "3/团队图片_04.png", name: "陈敏敏", info: "产品设计师" },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(0, 1000, false);
  },
  methods: {
    goto() {
      this.swiper.slideTo(0, 1000, true);
    },
    onSlideChange(swiper) {
      this.selectIndex = this.swiper.activeIndex;
      console.log("start :  " + this.swiper.activeIndex); //切换的时候获取到当前是第几个
    },
    gotoSwiperChange(index) {
      this.swiper.slideTo(index, 200, true);
    },
    leftPage(b) {
      let index = this.selectIndex;
      console.log(index);
      var tmp = index;
      if (b == true) {
        tmp = index + 1;
      } else {
        tmp = index - 1;
      }
      this.swiper.slideTo(tmp, 200, true);
    },
  },
};
</script>



<!-- 样式 -->
<style scoped>
.third {
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
}

.backimg {
  /* 禁止左右滚动 */
  overflow: auto;
  overflow-x: hidden;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: calc(100vh - 100px); */
  margin-bottom: 100px;

}

.logo {
}

/* logo是图片 */
.logo img {
  display: block;
  height: 45px;
  margin: 0 auto;

  margin-top: 20px;
}

/* logo下面的文字 */
.logoTxt {
  margin-top: 20px;
  text-align: center;

  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}

/* 成员 */
.people {
  width: 100%;
  margin-top: 60px;
}

/* swiper容器 */
.myswiperContainer {
  margin-top: 60px;
}

.scrollSide {
  /* border-width: 1px;
  border-color: red;
  border-style: solid; */

  /* height: 50vh; */
}

.avatorRow {
  display: flex;
  justify-content: space-around;
  margin-left: 80px;
  margin-right: 80px;
}

.card {
  background-color: rgba(0, 0, 0, 0);

  width: 18%;

  margin: 0;
  padding: 0;

  /* padding-left: 10px; */
  /* padding-right: 10px; */

  overflow: hidden;

  margin-bottom: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* background-color: #1977f3; */
}

.card:hover {
  background-color: #1977f3;
}

.card img {
  width: 100%;
  /* max-height: 400px; */
  /* height: auto; */
}

.card .namebox {
  height: 100px;
  /* width: 300px; */
  /* max-width: 300px; */
  /* min-width: 300px; */
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding-left: 0;

  border-bottom: 1px;
  border-bottom-color: #1977f3;
  border-bottom-style: solid;

  /* background-color: pink; */
}

/* .card .namebox:hover {
  background-color: #1977f3;
  padding-left: 10px;
} */

.card .namebox .name {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
}

.card .namebox .word {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.6;

  margin-top: 10px;
  margin-bottom: 20px;
}

/* 分页器 */
.bottomPage {
  height: 0px;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 40px;
  margin-bottom: 20px;
}

.bottomSwith {
  height: 40px;
  width: 100%;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.bottomSwith .switch {
  width: 40px;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* 未选中的 */
.my-bullet {
  width: 4px;
  height: 4px;

  border-radius: 2px;
  background-color: #7b7b7b;
}

/* 当前选中的 */
.my-bullet-active {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  border-width: 2px;
  border-color: #1977f3;
  border-style: solid;
  background-color: black;
}

.sideBtn {
  width: 30px;
  height: 30px;
  /* background-color: #1977F3; */

  display: flex;
  justify-content: center;
  align-items: center;

  border-width: 1px;
  border-color: red;
  border-style: solid;

  border-width: 1px;
  border-radius: 15px;
  border-style: solid;
  border-color: #737373;
}

.sideHeightBtn {
  width: 30px;
  height: 30px;
  /* background-color: #1977F3; */

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 15px;
  background-color: #1977f3;
}

.preBtn {
}

.preBtn img {
  width: 7px;
  height: 12px;
}

.nextBtn {
}

.nextBtn img {
  width: 7px;
  height: 12px;
}

/* 处于小屏幕 超小屏幕时logo要留出顶部距离 */
@media screen and (max-width: 767px) {

  .backimg {
  /* 禁止左右滚动 */
  overflow: auto;
  overflow-x: hidden;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: calc(100vh - 100px); */
  margin-bottom: 0px;

}

  .logo {
    margin-top: 60px;
  }

  .logo img {
    display: block;
    height: 30px;
    margin: 0 auto;
  }

  .card:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .card {
    background-color: rgba(0, 0, 0, 0);

    width: 45%;

    margin: 0;
    padding: 0;

    padding-left: 10px;
    padding-right: 10px;

    overflow: hidden;

    margin-bottom: 40px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .avatorRow {
    display: flex;
    justify-content: space-around;
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>