import { render, staticRenderFns } from "./ThirdPage.vue?vue&type=template&id=ff157c64&scoped=true&"
import script from "./ThirdPage.vue?vue&type=script&lang=js&"
export * from "./ThirdPage.vue?vue&type=script&lang=js&"
import style0 from "./ThirdPage.vue?vue&type=style&index=0&id=ff157c64&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.0@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff157c64",
  null
  
)

export default component.exports