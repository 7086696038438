import Vue from 'vue/dist/vue.js' //导入Vue
import VueRouter from 'vue-router' //导入路由插件

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter); //安装路由

//导入组件
import Hello from '../components/Hello'

import MyError from '../components/commonPage/MyError'


//配置路由
const routes = [

  /*默认跳转路由*/
  {
    path: '/',
    // redirect: '/home' //默认主页面
    component: Hello //跳转到组件
  },
  {
    path: '*',
    component: MyError //错误页面
  },

];

//实例化VueRouter 注意：名字
//hash：路径带# 符号，如 :http://localhost/#/login
//history：路径不带 #符号，如 : http://localhost/login
const router = new VueRouter({
  mode: 'history',
  base:'/dream/',
  routes // （缩写）相当于 routes: routes
});

//暴露
export default router;