<!-- 模式布局 -->
<template>
  <div class="myMap sss">
    <!--百度地图容器-->
    <!-- <div
      style="width: 100%; height: 100%; border: #ccc solid 1px"
      id="dituContent" 
    ></div> -->

    <img src="4/contact_ditu.png" alt="" align="middle">
  </div>
</template>

<!-- 脚本 -->

<script>
export default {
  data() {
    return {
      list: [],
    };
  },
  mounted() {

  },
  methods: {
    
  },
};
</script>

<!-- 样式 -->
<style scoped>
.myMap {
  width: 100%;
  height: 100%;

  /* background-color: pink; */
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.myMap img{

  height: 100%;
  width: 100%;
  
  /* object-fit: none; */
  /* object-fit: scale; */
  object-fit: cover;
  
}
</style>