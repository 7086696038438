import Vue from 'vue/dist/vue.js'
import App from './App'


//配置路由
import router from './router/router'

//ele配置
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import store from './store/store'

// 使用 vue-meta
import Meta from "vue-meta";
Vue.use(Meta);

//bootstrap
import 'bootstrap'
import $ from 'jquery'
/**
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js' 
 */


 // svg
import '@/assets/svg.js'
import MisIcon from '@/components/MisIcon.vue'
Vue.component('mis-icon', MisIcon)

// 全屏滚动 vue-fullpage.js
import 'fullpage.js/vendors/scrolloverflow';
import VueFullpage from 'vue-fullpage.js'
Vue.use(VueFullpage)

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper)



new Vue({
  el: '#app',
  router,
  store,
  components: {App},
  template: '<App/>',
})

