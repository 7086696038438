<template>
  <div>
    <div class="firstBox">
      <!-- <div class="container-fluid"> -->
      <!-- <div class="row"> -->
      <!-- 滚动 -->
      <swiper
        class="myswiperContainer sss"
        ref="mySwiper"
        :options="swiperOptions"
      >
        <swiper-slide
          class="scrollSide"
          v-for="(entity, index) in list"
          :key="index"
          :style="selectIndex == index ? entity.bgimg : '' "
        >
          <div class="inswiper">
            <div class="container-fluid">
              <div class="row bodyBox">
                <div class="col-md-3 leftBox">
                  <div>
                    <img class="leftBoxIn_1" :src="entity.logoimg" alt="" />
                  </div>
                  <div class="leftBoxIn_2">{{ entity.name }}</div>
                  <div class="leftBoxIn_3">
                    {{ entity.info }}
                  </div>
                  <div class="leftBoxIn_4 d-none d-sm-block" @click="gotoNext">
                    <img :src="entity.next" />
                  </div>
                </div>

                <div class="col-md-9 bigAppImg sss">
                  <img :src="selectIndex == index ? entity.bigAppImg : ''" />
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper-slide>
      </swiper>

      <div class="container">
        <div class="row">
          <div class="bottomSwith">
            <div
              @click="gotoSwiperChange(index)"
              :class="{
                switch: true,
              }"
              v-for="(entity, index) in list"
              :key="index"
            >
              <img
                :class="{
                  'my-bullet': selectIndex != index,
                  'my-bullet-active': selectIndex == index,
                }"
                :src="entity.logoimg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "carrousel",
  data() {
    return {
      selectIndex: 0,
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
        },
        on: {
          slideChangeTransitionStart: this.onSlideChange,
        },
      },
      list: [
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/1底图背景_01.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/01/第一屏_09.png",
          name: "66乐园",
          info: "抖音是一个帮助用户表达自我、记录美好生活的平台。截至2020年8月，包含抖音火山版在内，抖音日活跃用户超过6亿，并继续保持高速增长。",
          next: "1/01/第一屏_13.png",
          bottomPage: "1/01/第一屏_17.png",
          bigAppImg: "1/01/第一屏_07.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_gundong.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/03/prodoct_icon_jieping.png",
          name: "滚动截屏",
          info: "滚动截屏是一款用录屏来进行长截图拼接的实用工具，一次录屏就能完成长截图。更有多张图片自动拼接、快速导出高清长截图功能。还能对图片中的隐私信息进行马赛克遮盖，添加水印标明图片出处。",
          next: "1/03/img_jieping22.png",
          bottomPage: "1/03/prodoct_qiehuan_jieping.png",
          bigAppImg: "1/03/img_jieping.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_biaoge.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/02/prodoct_icon_biaoge.png",
          name: "手机表格制作",
          info: "手机表格制作是一款简单实用的Excel手机制表工具，快速完成表格制作，支持字体，字号，颜色多样设置，内置海量常用模板供您使用，提高您的办公效率。",
          next: "1/02/img_biaoge22.png",
          bottomPage: "1/02/prodoct_qiehuan_biaoge22.png",
          bigAppImg: "1/02/img_biaoge.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_duokai.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/04/prodoct_icon_duokai3.png",
          name: "多开应用分身",
          info: "多开助手是一款基于安卓虚拟化技术的手机分身工具，一部手机轻松实现几乎所有应用的多账户同时在线，完美解决用户多账户同时在线的需求；更有应用锁等隐私保护功能！",
          next: "1/04/img_duokai22.png",
          bottomPage: "1/04/prodoct_qiehuan_duokai.png",
          bigAppImg: "1/04/img_duokai.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_php2.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/05/prodoct_icon_php44.png",
          name: "PHP中文网",
          info: "PHP中文网(php.cn)提供大量免费、原创、高清的PHP视频教程，学习的时候可以直接在线修改示例，查看PHP执行效果。PHP从入门到精通，一站式PHP自学平台，帮助PHP学习者快速成长！",
          next: "1/05/img_php3.png",
          bottomPage: "1/05/prodoct_qiehuan_php6.png",
          bigAppImg: "1/05/img_php.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_php2.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/06/prodoct_icon_biaoge55.png",
          name: "街机盒子",
          info: "街机盒子是一个综合性游戏平台，在这里，找到童年快乐，休闲娱乐你的枯燥生活，这里汇集全网各式游戏类型，例如最热单机游戏，经典街机IP，同样我们支持FC小霸王红白机游戏、GBA游戏、SFC游戏、PSP游戏、MD游戏、NDS等热门游戏平台模拟器功能。",
          next: "1/06/img_jieji33.png",
          bottomPage: "1/06/prodoct_qiehuan_jieji.png",
          bigAppImg: "1/06/img_jieji.png",
        },
        {
          bgimg: {
            backgroundImage:
              "url(" + require("../../assets/images/bg_php2.png") + ")",
            // 背景图片是否重复
            backgroundRepeat: "no-repeat",
            // 背景图片大小
            backgroundSize: "cover",
            // 背景图片位置
            backgroundPosition: "center center",
          },
          logoimg: "1/07/prodoct_icon_biaoge55.png",
          name: "查妆助手",
          info: "专注于化妆品保质期查询领域，拥有350+个品牌的批号解读数据库，已为用户提供6380万余次查询服务， 记录5220万余条保质期提醒，是您管理化妆品的好帮手。",
          next: "1/07/img_jieji33.png",
          bottomPage: "1/07/prodoct_qiehuan_jieji.png",
          bigAppImg: "1/07/img_jieji.png",
        },
      ],
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    console.log("Current Swiper instance object", this.swiper);
    this.swiper.slideTo(0, 1000, false);
  },
  methods: {
    goto() {
      this.swiper.slideTo(1, 1000, true);
    },
    onSlideChange(swiper) {
      console.log(swiper.activeIndex);
      this.selectIndex = swiper.activeIndex;
      // console.log("start :  " + this.activeIndex); //切换的时候获取到当前是第几个
      // this.selectIndex = this.activeIndex;
    },
    gotoSwiperChange(index) {
      this.swiper.slideTo(index, 200, true);
    },
    gotoNext() {
      this.swiper.slideTo(this.selectIndex + 1, 200, true);
    },
  },
};
</script>



<!-- 样式 -->
<style scoped>
.firstBox {
  /* 禁止左右滚动 */
  overflow: auto;
  overflow-x: hidden;

  position: relative;

  /* 顶部100px */
  margin-top: 100px;
}

/* 左右滑动容器 */
.myswiperContainer {
  margin: 0;
  padding: 0;

  position: relative;
  /* height: 100%; */

  /* overflow: hidden; */
}

.inswiper {
  width: 100%;
  height: 100%;
}

/* 滚动item容器 */
.scrollSide {
  width: 100%;
  height: calc((100vh) - 100px);
}

.scrollSide .bodyBox .leftBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.scrollSide .inswiper {
  display: flex;
  align-items: center;
}

.scrollSide .bodyBox .leftBoxIn_1 {
  width: 80px;
  height: 80px;
}

.scrollSide .bodyBox .leftBoxIn_2 {
  margin-top: 30px;

  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  line-height: 26px;
}

.scrollSide .bodyBox .leftBoxIn_3 {
  margin-top: 26px;

  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
}

.scrollSide .bodyBox .leftBoxIn_4 img {
  width: 66px;
  height: 66px;

  margin-top: 20px;
}

.bodyBox .bigAppImg {
  /* width: 100%; */
  /* height: 100%; */

  display: flex;
  justify-content: center;
  align-items: center;
}

.bodyBox .bigAppImg img {
  width: 96%;
  height: auto;
}

.bottomSwith {
  z-index: 10;
  height: 80px;
  width: 100%;
  /* overflow: hidden; */

  /* margin-bottom: 10px; */

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}

.bottomSwith .switch {
  width: 64px;
  height: 64px;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* 未选中的 */
.my-bullet {
  width: 72%;
  height: 72%;
}

/* 当前选中的 */
.my-bullet-active {
  width: 100%;
  height: 100%;
}

/* .my-bullet img{
  width: 50%;
  height: 50%;
}

.my-bullet-active img{
  width: 100%;
  height: 100%;
} */

/* 当处于超小屏时 */
@media screen and (max-width: 767px) {
  .firstBox {
    /* 禁止左右滚动 */
    overflow: auto;
    overflow-x: hidden;

    position: relative;
    padding-bottom: 60px;

    /* 顶部100px */
    margin-top: 100px;
  }

  .bodyBox .bigAppImg img {
    margin-top: 10px;
    margin-bottom: 10px;
    /* margin-bottom: 80px; */

    /* background-color: pink; */
  }

  .scrollSide {
    width: 100%;
    height: 100%;
  }

  .bottomSwith {
    z-index: 10;
    height: 50px;
    width: 100%;
    /* overflow: hidden; */

    /* margin-bottom: 10px; */

    display: flex;
    justify-content: center;
    align-items: center;

    /* position: absolute; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
  }

  .bottomSwith .switch {
    width: 50px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>