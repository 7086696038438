<template>
  <div>
    <!-- 导航栏 -->
    <top-head :selectPage="selectPage" :selectBlock="clickIndex"></top-head>
    <!-- 分页插件 -->
    <div class="myScroll" v-if="isSmallScreen">
      <full-page :options="options" ref="fullpage">
        
        <first-page class="section"></first-page>
        <second-page class="section"></second-page>
        <third-page class="section"></third-page>
        <four-page class="section"></four-page>
      </full-page>
    </div>
    <div class="smallScreen">
      <first-page></first-page>
      <second-page></second-page>
      <third-page></third-page>
      <four-page></four-page>
    </div>
  </div>
</template>

<script>
import TopHead from "./home/TopHead.vue";
import FirstPage from "./home/FirstPage.vue";
import FourPage from "./home/FourPage.vue";
import SecondPage from "./home/SecondPage.vue";
import ThirdPage from "./home/ThirdPage.vue";
import First from "./home/First.vue";
export default {
  components: { FirstPage,SecondPage,ThirdPage,FourPage,TopHead },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      isSmallScreen: true,
      isFirst: true,
      count: 0,
      changeWidth: false,
      selectPage: 0,
      options: {
        licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
        //是否显示导航，默认为false
        navigation: true,
        //为每个section设置背景色
        // sectionsColor: ["#41b883", "#ff5f45", "#0798ec", "#fec401"],
        afterLoad: this.afterLoad,
        verticalCentered:false,
        // menu:"#menu"
      },
    };
  },

  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
    this.loadData();
  },
  watch: {
    screenWidth: {
      handler(newv, oldv) {
        let ow = this.isSmallScreen;
        this.isSmallScreen = document.body.clientWidth > 767;
        this.count++;

        this.changeWidth = ow != this.isSmallScreen;

        if (this.count > 1) {
          this.isFirst = false;
        }

        if (this.changeWidth) {
          if (this.isFirst == false) {
            // 重新刷新页面
            // location.reload(true);
          }
        }

        console.log("this.screenWidth = " + this.screenWidth);
      },
      immediate: true,
    },
  },

  methods: {
    loadData() {},

    afterLoad(link, index) {
      this.selectPage = index.index;
    },

    clickIndex(index){
      this.selectPage = index
      this.$refs.fullpage.api.moveTo(index + 1,0)

    }
  },
};
</script>


<style scope>
* {
  margin: 0;
  padding: 0;
}

.section {
  /* padding-top: 100px; */
}
</style>

<style>
/* 活跃的颜色 */
#fp-nav ul li a.active span {
  background: #1977f3;
}

/* 正常的颜色 */
#fp-nav ul li a span {
  background: grey;
  height: 2px;
}

#fp-nav ul li a {
  display: block;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 2px;
  overflow: hidden;
}
</style>

<style>
.sss {
  /* border-width: 1px;
  border-color: red;
  border-style: solid; */
}

@media screen and (min-width: 1300px) {
  .container-fluid {
    width: 1300px;
    margin: 0 auto;
  }

  * {
    margin: 0;
    padding: 0;
  }
}

@media screen and (min-width: 1600px) {
  .container-fluid {
    width: 1600px;
    margin: 0 auto;
  }

  * {
    margin: 0;
    padding: 0;
  }
}
</style>
